<template>
  <div class="news__detail__container">
    <div class="news__detail__inner" v-style ref="ndi">
      <div class="detail__inner">
        <div class="detail__text__container">
          <div class="text__publish scrollObj">{{ detail.publish }}</div>
          <div class="sticky-container" ref="sc">
            <router-link
              class="back-prev-page hidden"
              id="nmb"
              :to="{
                path: '/news'
              }"
            >
              <div class="inner">
                <p>BACK TO ALL</p>
                <div class="line"></div>
              </div>
            </router-link>
            <div class="fixed_info" ref="fixedInfo">
              <div class="info__container" ref="info__container" v-html="currentFixedInfo"></div>
            </div>
          </div>
        </div>
        <div class="detail__img__container">
          <div class="detail__title scrollObj">{{ detail.title }}</div>
          <div class="detail__img-content" v-if="detail.content && detail.content.length > 0">
            <div
              class="img-box scrollObj"
              v-for="(img, index) in detail.content"
              :key="index"
              :data-html="img.html ? img.html : ''"
              :class="{
                fixed: img.html
              }"
            >
              <img :src="img.value" alt="" />
              <div
                class="mobile__text scrollObj"
                v-if="$bowser.mobile && img.html"
                v-html="img.html"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="recommend__container">
      <div class="recommend__inner" v-if="recommend && recommend.length > 0">
        <div class="recommend__lists">
          <div class="list__item scrollObj" v-for="(item, index) in recommend" :key="index">
            <div class="item__inner">
              <div class="item__cover">
                <img :src="item.inner_big_img" alt="" />
              </div>
              <div class="item__text-info">
                <div class="info__title">{{ item.title }}</div>
                <div class="info__publish">{{ item.publish }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="recommend__scroll-hint scrollObj" v-if="$bowser.mobile">
        <div class="hint-icon"></div>
        <p class="hint-text">back</p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import GSAP from 'gsap';
import events from '@/utils/eventBus';
import { newsDetail } from '@/api/data';

export default {
  name: 'views-news-detail',
  data() {
    return {
      detail: {
        title: '',
        publish: '',
        content: null,
      },
      recommend: null,
      currentFixedInfo: '',
      fixedHtml: null,
    };
  },
  computed: {
    ww() {
      return window.innerHeight;
    },
    id() {
      return this.$route.params.id;
    },
  },
  directives: {
    style: {
      inserted: (el) => {
        el.setAttribute(
          'data-paddingleft',
          parseInt(window.getComputedStyle(el, null).paddingLeft, 10),
        );
      },
    },
  },
  watch: {
    currentFixedInfo(val) {
      if (val && !GSAP.isTweening(this.$refs.info__container)) {
        GSAP.fromTo(
          this.$refs.info__container,
          {
            y: 100,
            opacity: 0,
          },
          {
            y: 0,
            opacity: 1,
            duration: 0.8,
          },
        );
        this.$nextTick(() => {
          const $obj = this.$refs.sc;
          const left = this.$refs.ndi.dataset.paddingleft;
          $obj.style.cssText = `top:${this.ww
            - document.querySelector('.sticky-container').offsetHeight
            - left}px`;
        });
      }
    },
    $route: {
      handler() {
        this.getmore();
      },
      immediate: true,
    },
  },
  mounted() {},
  destroyed() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      const show = [...this.fixedHtml].find((item) => {
        const { top } = item.getBoundingClientRect();
        const isInView = top <= window.innerHeight && top > 0;
        return isInView;
      });
      if (
        show
        && show.dataset.html !== this.currentFixedInfo
        && !GSAP.isTweening(this.$refs.info__container)
      ) {
        GSAP.fromTo(
          this.$refs.info__container,
          {
            y: 0,
            opacity: 1,
          },
          {
            y: -100,
            opacity: 0,
            duration: 0.8,
            onComplete: () => {
              this.currentFixedInfo = show.dataset.html;
            },
          },
        );
      }
    },
    getmore() {
      const that = this;
      newsDetail(that.id).then((res) => {
        const { data } = res;
        if (data) {
          that.detail.title = data.detail.title;
          that.detail.publish = data.detail.publish;
          that.detail.content = data.detail.content;
          that.recommend = data.latest;
        }
        that.$nextTick(() => {
          that.fixedHtml = document.querySelectorAll('.fixed');
          window.addEventListener('scroll', that.onScroll);
          events.$emit('pageMounted');
          that.onScroll();
          that.pageInit();
        });
      });
    },
  },
};
</script>

<style></style>
